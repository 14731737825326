.marker-labels-active {
    top: 25px;
    left: -30px;
    font-size: 10px !important;
    position: relative;
    background: #15e01f !important;
    /* border: 1px solid #000000; */
    box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.3);
    color: #fff !important;
    padding: 0px 20px;
    border-radius: 5px;
    /* font-family: Arial, sans-serif; */
    /* font-size: 16px; */
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
}

.marker-labels-idle {
    top: 25px;
    left: -30px;
    font-size: 10px !important;
    position: relative;
    background: #e9821b !important;
    /* border: 1px solid #000000; */
    box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.3);
    color: #fff !important;
    padding: 0px 20px;
    border-radius: 5px;
    /* font-family: Arial, sans-serif; */
    /* font-size: 16px; */
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
}

.marker-labels-offline {
    top: 25px;
    left: -30px;
    font-size: 10px !important;
    position: relative;
    background: #fc1717 !important;
    /* border: 1px solid #000000; */
    box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.3);
    color: #fff !important;
    padding: 0px 20px;
    border-radius: 4px;
    /* font-size: 16px; */
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
}

.infowindow-container {
    /* background: #212529!important; */
    padding: 15px;
    border-radius: 8px;
    position: absolute !important;
    min-width: 350px !important;
    left: -180px !important;
    bottom: 20px !important;
}

.infowindow-active {
    box-shadow: 10px 10px 5px rgba(0, 0, 0, .2);
    border-radius: 8px;
    padding: 4px 15px 0;
    background-color: #fff;
    transition: all 0.3s ease-out;
}

.infowindow-active .MuiCardContent-root {
    z-index: 9995;
    display: block !important;
}

.infowindow-active .MuiAvatar-root {
    display: none;
}

.gm-ui-hover-effect {
    width: 18px !important;
    height: 18px !important;
}

.gm-ui-hover-effect>span {
    margin: 0px !important;
    width: 18px !important;
    height: 18px !important;
}